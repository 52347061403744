import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import materialRoutes from 'app/views/material-kit/MaterialRoutes'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import listingRoutes from 'app/views/listings/ListingRoutes';
import bookingRoutes from 'app/views/Bookings/BookingsRoutes';
import calenderRoutes from 'app/views/calender/CalenderRoutes';
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'
import reportRoutes from 'app/views/Report/ReportRoutes'

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <MatxLayout/>
                </AuthGuard> 
            ),
            children: [...dashboardRoutes,...materialRoutes,...listingRoutes, ...bookingRoutes, ...calenderRoutes,...reportRoutes],
        },
        ...sessionRoutes,
        {
            path: '/',
            element: <Navigate to="dashboard"/>,
        },
        {
            path: '*',
            element: <NotFound/>,
        },
    ]
    return all_routes;
}