import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const Sceduler = Loadable(lazy(() => import("./Sceduler")));
const calenderRoutes = [
    {
        path: '/calender',
        element: <Sceduler />,
    }
]

export default calenderRoutes;
