import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const ReportEvents = Loadable(lazy(() =>import('./ReportEvents')));
const ReportPayments = Loadable(lazy(() =>import('./ReportPayments')));
const reportRoutes = [
    {
        path: 'report/events',
        element: <ReportEvents />,
    },
    {
        path: '/report/payments',
        element: <ReportPayments />,
    },
]

export default reportRoutes