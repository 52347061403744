import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const NotFound = Loadable(lazy(() => import("./NotFound")));
const ForgotPassword = Loadable(lazy(() => import("./ForgotPassword")));
const JwtLogin = Loadable(lazy(() => import("./login/JwtLogin")));

const sessionRoutes = [
    {
        path: '/vendors/sign_in',
        element: <JwtLogin />,
    },
    {
        path: '/vendors/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/404',
        element: <NotFound />,
    },
]

export default sessionRoutes
