import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const ChangePassword = Loadable(lazy(() => import("./ChangePassword")));
const DashboardPortal = Loadable(lazy(() => import('./DashboardPortal')))
const Notification = Loadable(lazy(() => import("./Notification/Notification")));
const dashboardRoutes = [
    {
        path: '/dashboard',
        element: <DashboardPortal />,
    },
    {
        path: '/vendors/change-password',
        element: <ChangePassword />,
    },
    {
        path: '/notifications',
        element: <Notification />,
    },
]

export default dashboardRoutes
