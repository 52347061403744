import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const BookingsListing = Loadable(lazy(() => import("./BookingsListing")));
const BookingsDetail = Loadable(lazy(() => import("./BookingsDetail")));

const bookingRoutes = [
    {
        path: '/bookings',
        element: <BookingsListing />,
    },
    {
        path: '/bookings/:id',
        element: <BookingsDetail />,
    },
    
]

export default bookingRoutes;
