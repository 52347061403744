import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const AddService = Loadable(lazy(() => import("./service/AddService")));
const EditService = Loadable(lazy(() => import("./service/EditService")));
const ListService = Loadable(lazy(() => import("./service/ListService")));

const listingRoutes = [
    {
        path: '/listing/services',
        element: <ListService />,
    },
    {
        path: '/listing/services/create',
        element: <AddService />,
    },
    {
        path: '/listing/services/edit/:id',
        element: <EditService />,
    },
    
]

export default listingRoutes
