import React from 'react'
import { Store } from './redux/Store'
import { Provider } from 'react-redux'
import { AllPages } from './routes/routes'
import { MatxTheme } from 'app/components'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
const App = () => {
    const all_pages = useRoutes(AllPages())
    return (
        <Provider store={Store}>
            <MatxTheme>
                <AuthProvider>{all_pages}</AuthProvider>
            </MatxTheme>
        </Provider>
    )
}
export default App
