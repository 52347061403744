export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'dashboard',
    },
    {
        name: 'Listings',
        path: '/listing/services',
        icon: 'list',
    },
    {
        name: 'Bookings',
        path: '/bookings',
        icon: 'developer_board',
    },
    {
        name: 'Calendar',
        path: '/calender',
        icon: 'event',
    },
    {
        name: 'Reports',
        icon: 'report',
        children: [
            {
                name: 'Events',
                path: '/report/events',
                icon: ' event_available',
            },
            {
                name: 'Payment Logs',
                path: '/report/payments',
                 icon: 'payment',
            },
        ]
    }
]
